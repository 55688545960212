import * as React from 'react';
import styled from '@emotion/styled';
import { Table, Tbody, Td, Th, Tr } from 'components/atoms/table';
import { AboutNav } from 'components/organisms/aboutNav';
import { AboutSection } from 'components/organisms/aboutSection';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { mq } from 'style/variables';
import FadeUpItem from 'components/organisms/fadeUpItem';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import { AboutCompanyQuery } from 'types/graphql-types';
import sizes from 'style/sizes';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface Props {
  data: AboutCompanyQuery;
}
const CompanySection = styled(AboutSection)`
  padding-top: 0;
  padding-bottom: ${sizes.margin[100]};
  a {
    text-decoration: underline;
    ${mq.onlypc} {
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
`;

const Pages: React.FC<Props> = props => {
  const profiles = props.data.allShifterProfiles.edges;

  return (
    <>
      <SEO title={'Company | About'} />
      <ContentWrapper>
        <AboutNav heading={'Company'} />
        <CompanySection>
          <FadeUpItem delay={0.6}>
            <Table>
              <Tbody>
                {profiles.map((profile, key) => (
                  <Tr key={key}>
                    <Th>{profile.node.title?.rendered || ''}</Th>
                    <Td dangerouslySetInnerHTML={{ __html: profile.node.acf?.text || '' }} />
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </FadeUpItem>
        </CompanySection>
      </ContentWrapper>

      <InquiryBlock />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query AboutCompany {
    site {
      siteMetadata {
        title
      }
    }
    allShifterProfiles(sort: { fields: acf___show_num, order: ASC }) {
      edges {
        node {
          id
          acf {
            text
          }
          title {
            rendered
          }
        }
      }
    }
  }
`;
