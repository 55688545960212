import styled from '@emotion/styled';
import React from 'react';
import fonts from 'style/fonts';
import { mq } from 'style/variables';
import sizes from 'style/sizes';

export type TableType = {};
export type TheadType = {};
export type TbodyType = {};
export type TrType = {};
export type ThType = {};
export type TdType = {} & JSX.IntrinsicElements['td'];

export const Table: React.FC<TableType> = props => {
  return <TableContainer {...props}>{props.children}</TableContainer>;
};

export const Thead: React.FC<TableType> = props => {
  return <TheadContainer {...props}>{props.children}</TheadContainer>;
};

export const Tbody: React.FC<TableType> = props => {
  return <TbodyContainer {...props}>{props.children}</TbodyContainer>;
};

export const Tr: React.FC<TableType> = props => {
  return <TrContainer {...props}>{props.children}</TrContainer>;
};

export const Th: React.FC<TableType> = props => {
  return (
    <ThContainer {...props}>
      <span>{props.children}</span>
    </ThContainer>
  );
};

export const Td: React.FC<TdType> = props => {
  return <TdContainer {...props}>{props.children}</TdContainer>;
};

const TableContainer = styled('table')`
  font-size: 15px;
  line-height: 32px;
`;

const TheadContainer = styled('thead')``;

const TbodyContainer = styled('tbody')``;

const TrContainer = styled('tr')`
  ${mq.onlysp} {
    display: block;
    & + & {
      margin-top: 12px;
    }
  }
`;

const ThContainer = styled('th')`
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: keep-all;
  font-weight: normal;
  min-width: 148px;
  font-family: ${fonts.jaGothic};
  font-weight: 600;
  ${mq.onlysp} {
    margin-bottom: 0.6em;
  }
`;

const TdContainer = styled.td<TdType>`
  border: none;
  font-family: ${fonts.jaGothic};
  padding-bottom: 2.3em;
  ${mq.onlysp} {
    padding-left: ${sizes.margin[16]};
  }
`;

export default Table;
